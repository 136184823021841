import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaLightbulb, FaHandshake, FaStar, FaHeart } from 'react-icons/fa'; // Import icons

const OurValues = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState("8rem"); // Default value for smaller screens

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Adjust height dynamically based on screen size and detect mobile
      if (width >= 1884) {
        setDynamicHeight("16rem");
        setIsMobile(false);
      } else if (width >= 1330) {
        setDynamicHeight("10rem");
        setIsMobile(false);
      } else if (width >= 768) {
        setDynamicHeight("8rem");
        setIsMobile(false);
      } else {
        setDynamicHeight("6rem");
        setIsMobile(true); // Mobile layout for screens less than 768px
      }
    };

    // Set the initial height and check for mobile
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Variants for the overall container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for text elements to animate
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Hover animation variants for text and image
  const hoverVariants = {
    hover: {
      rotate: 2,
      scale: 1.06,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        type: "spring",
        stiffness: 300,
      },
    },
  };

  // Mobile Layout (cards for each value with icons)
  const mobileLayout = (
    <motion.div
      className="relative bg-cover bg-center min-h-screen w-screen text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/Our Value Base.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Background Overlay for Better Readability */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent"></div>

      {/* Foreground Image with Rounded Corners and Shadow */}
      <div className="p-2 w-full h-full flex justify-center items-center">
        <motion.img
          src="/assets/Our Value Image.png"
          alt="Our Values"
          className="rounded-xl shadow-xl h-full w-full object-contain "
          variants={textVariants}
          whileHover={{ scale: 1.05, transition: { duration: 0.5, ease: "easeInOut" }}}
        />
      </div>

      <motion.div
        className="flex flex-col justify-center items-center text-center p-6 relative z-10"
        variants={textVariants}
      >
        <motion.h2 className="text-4xl text-black  mb-2" variants={textVariants}>
          Our Values
        </motion.h2>

        {/* Cards for Each Value with Icons */}
        <div className="grid grid-cols-1 gap-6 w-full max-w-md">
          {/* Creativity Card */}
          <motion.div
            className="bg-gradient-to-r from-pink-400 to-pink-600 text-white p-6 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300"
            variants={textVariants}
          >
            <h3 className="text-2xl font-semibold mb-3 flex items-center">
              <FaLightbulb className="mr-2 text-2xl" /> Creativity
            </h3>
            <p className="text-base leading-relaxed">
              We foster innovation and encourage unique storytelling.
            </p>
          </motion.div>

          {/* Collaboration Card */}
          <motion.div
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white p-6 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300"
            variants={textVariants}
          >
            <h3 className="text-2xl font-semibold mb-3 flex items-center">
              <FaHandshake className="mr-2 text-2xl" /> Collaboration
            </h3>
            <p className="text-base leading-relaxed">
              We believe in the power of teamwork and partnership.
            </p>
          </motion.div>

          {/* Quality Card */}
          <motion.div
            className="bg-gradient-to-r from-purple-400 to-purple-600 text-white p-6 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300"
            variants={textVariants}
          >
            <h3 className="text-2xl font-semibold mb-3 flex items-center">
              <FaStar className="mr-2 text-2xl" /> Quality
            </h3>
            <p className="text-base leading-relaxed">
              We strive for excellence in every aspect of our work.
            </p>
          </motion.div>

          {/* Passion Card */}
          <motion.div
            className="bg-gradient-to-r from-red-400 to-red-600 text-white p-6 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300"
            variants={textVariants}
          >
            <h3 className="text-2xl font-semibold mb-3 flex items-center">
              <FaHeart className="mr-2 text-2xl" /> Passion
            </h3>
            <p className="text-base leading-relaxed">
              We are driven by our love for filmmaking and all things movies!
            </p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );

  // Desktop Layout (with images and original layout)
  const desktopLayout = (
    <motion.div
      className="relative bg-cover bg-center min-h-screen w-screen text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/Our Value Base.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Foreground Image and Content Section */}
      <div
        className="absolute bottom-0 w-screen"
        style={{ height: `calc(100% - ${dynamicHeight})` }}
      >
        <motion.img
          src="/assets/Our Value Image.png"
          alt="Our Values"
          className="absolute right-0 top-[30%] w-[50%] flex flex-row justify-center items-center"
          variants={textVariants}
          whileHover={hoverVariants.hover} // Apply hover effect
        />

        {/* Content Section */}
        <section className="relative z-10 flex flex-col justify-center items-start w-1/2 text-right px-4 md:px-12 lg:px-24 h-full">
          <motion.div
            className="absolute p-6 rounded-lg max-w-5xl text-left top-[-60px]"
            variants={containerVariants}
          >
            <motion.h2
              className="col-span-2 text-4xl font-bold mb-4 text-black"
              variants={textVariants}
              whileHover={hoverVariants.hover} // Apply hover effect
            >
              Our Values
            </motion.h2>

            {/* Grid container for 2x2 divs */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                variants={textVariants}
                whileHover={hoverVariants.hover} // Apply hover effect
                className="mb-4 pr-8 max-w-60 border-r"
              >
                <h3 className="text-xl font-semibold mb-2">Creativity:</h3>
                <p className="text-lg leading-relaxed">
                  We foster innovation and encourage unique storytelling.
                </p>
              </motion.div>

              <motion.div
                variants={textVariants}
                whileHover={hoverVariants.hover} // Apply hover effect
                className="mb-4 pl-8 max-w-60"
              >
                <h3 className="text-xl font-semibold mb-2">Collaboration:</h3>
                <p className="text-lg leading-relaxed">
                  We believe in the power of teamwork and partnership.
                </p>
              </motion.div>

              <motion.div
                variants={textVariants}
                whileHover={hoverVariants.hover} // Apply hover effect
                className="mb-4 pr-8 max-w-60 border-r"
              >
                <h3 className="text-xl font-semibold mb-2">Quality:</h3>
                <p className="text-lg leading-relaxed">
                  We strive for excellence in every aspect of our work.
                </p>
              </motion.div>

              <motion.div
                variants={textVariants}
                whileHover={hoverVariants.hover} // Apply hover effect
                className="mb-4 pl-8 max-w-60"
              >
                <h3 className="text-xl font-semibold mb-2">Passion:</h3>
                <p className="text-lg leading-relaxed">
                  We are driven by our love for filmmaking and all things
                  movies!
                </p>
              </motion.div>
            </div>
          </motion.div>
        </section>
      </div>
    </motion.div>
  );

  // Render the appropriate layout based on screen size
  return isMobile ? mobileLayout : desktopLayout;
};

export default OurValues;
