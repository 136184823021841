import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaHome, FaInfoCircle, FaHandsHelping, FaServicestack, FaEnvelope } from 'react-icons/fa'; // Importing icons

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="md:fixed sticky block top-0 w-full  md:bg-opacity-50 p-4 z-20 bg-gray-800 md:bg-transparent">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <motion.img 
            src="/assets/Avendia Logo.png" 
            alt="Avendia Logo" 
            className="w-12 h-12 md:w-16 md:h-16" 
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />
          <motion.span 
            className="text-white text-xl md:text-2xl ml-4 font-bold"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            Avendia Pte Ltd
          </motion.span>
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-6 lg:space-x-8 text-white text-lg font-medium">
          <li>
            <Link to="/" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/' ? 'text-yellow-900' : ''}`}>
              <FaHome className="mr-2" /> Home
            </Link>
          </li>
          <li>
            <Link to="/about" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/about' ? 'text-yellow-900' : ''}`}>
              <FaInfoCircle className="mr-2" /> About Us
            </Link>
          </li>
          <li>
            <Link to="/values" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/values' ? 'text-yellow-900' : ''}`}>
              <FaHandsHelping className="mr-2" /> Our Values
            </Link>
          </li>
          <li>
            <Link to="/services" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/services' ? 'text-yellow-900' : ''}`}>
              <FaServicestack className="mr-2" /> Services
            </Link>
          </li>
          <li>
            <Link to="/contacts" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/contacts' ? 'text-yellow-900' : ''}`}>
              <FaEnvelope className="mr-2" /> Contact Us
            </Link>
          </li>
        </ul>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button className="text-white focus:outline-none" onClick={handleMenuToggle}>
            <motion.svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              whileHover={{ rotate: 90 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </motion.svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <motion.div 
          className="md:hidden bg-gray-800 sticky  bg-opacity-90 p-4 flex flex-col space-y-4 text-white text-lg font-medium"
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <Link to="/" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/' ? 'text-yellow-900' : ''}`} onClick={handleMenuToggle}>
            <FaHome className="mr-2" /> Home
          </Link>
          <Link to="/about" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/about' ? 'text-yellow-900' : ''}`} onClick={handleMenuToggle}>
            <FaInfoCircle className="mr-2" /> About Us
          </Link>
          <Link to="/values" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/values' ? 'text-yellow-900' : ''}`} onClick={handleMenuToggle}>
            <FaHandsHelping className="mr-2" /> Our Values
          </Link>
          <Link to="/services" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/services' ? 'text-yellow-900' : ''}`} onClick={handleMenuToggle}>
            <FaServicestack className="mr-2" /> Services
          </Link>
          <Link to="/contacts" className={`hover:text-yellow-500 flex items-center ${location.pathname === '/contacts' ? 'text-yellow-900' : ''}`} onClick={handleMenuToggle}>
            <FaEnvelope className="mr-2" /> Contact Us
          </Link>
        </motion.div>
      )}
    </nav>
  );
};

export default Navbar;
