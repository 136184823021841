import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState("8rem"); // Default value for smaller screens

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Adjust height dynamically based on screen size
      if (width >= 1884) {
        setDynamicHeight("16rem");
        setIsMobile(false);
      } else if (width >= 1330) {
        setDynamicHeight("10rem");
        setIsMobile(false);
      } else if (width >= 768) {
        setDynamicHeight("8rem");
        setIsMobile(false);
      } else {
        setDynamicHeight("6rem");
        setIsMobile(true); // For screens smaller than 768px, consider it mobile
      }
    };

    // Set the initial height and mobile check
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Variants for the overall container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for the paragraphs to animate in from the bottom with fade-in
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Mobile Layout (simple text-focused view with a background image)
  const mobileLayout = (
    <motion.div
      className="relative bg-cover bg-center min-h-screen w-screen text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/About Us Base.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
  
      <motion.div
        className="flex flex-col justify-center items-center text-center p-6 min-h-screen relative z-10"
        variants={textVariants}
      >
        {/* Heading with vibrant color and subtle hover animation */}
        <motion.h2
          className="text-4xl font-extrabold text-gray-300 mb-6 tracking-wider"
          variants={textVariants}
          whileHover={{ scale: 1.05, transition: { duration: 0.5, ease: "easeInOut" }}}
        >
          About Us
        </motion.h2>
  
        {/* Paragraphs with improved typography and spacing */}
        <motion.p
          className="text-lg leading-relaxed mb-6 text-gray-300"
          variants={textVariants}
          whileHover={{ scale: 1.02, transition: { duration: 0.5, ease: "easeInOut" }}}
        >
          We are passionate about films and committed to providing comprehensive production solutions.
        </motion.p>
  
        <motion.p
          className="text-lg leading-relaxed text-gray-300"
          variants={textVariants}
          whileHover={{ scale: 1.02, transition: { duration: 0.5, ease: "easeInOut" }}}
        >
          At Avendia, we combine creativity, technical expertise, and a deep understanding of the industry to bring stories to life on screen.
        </motion.p>
      </motion.div>
    </motion.div>
  );
  

  // Desktop Layout (existing layout with images)
  const desktopLayout = (
    <motion.div
      className="relative bg-cover bg-center min-h-screen w-screen text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/About Us Base.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Foreground Image */}
      <div
        className="absolute bottom-0 w-screen"
        style={{ height: `calc(100% - ${dynamicHeight})` }}
      >
        <motion.img
          src="/assets/About Us Image.png"
          alt="About Us"
          className="absolute left-0 top-0 h-full w-[55%] md:w-[45%] lg:w-[40%] xl:w-[35%]"
          initial={{ y: -300 }}
          animate={{ y: 0, transition: { duration: 3, ease: "easeInOut", type: 'spring', stiffness: 300 } }}
          whileHover={{scale:1.05, transition: { duration: 3, ease: "easeInOut", type: 'spring', stiffness: 300 }  }}
        />

        {/* Content Section */}
        <section className="mt-[-60px] z-10 flex flex-col justify-center items-end text-right px-4 md:px-12 lg:px-24">
          <motion.div
            className="p-6 rounded-lg max-w-3xl text-justify "
            variants={containerVariants}
          >
            <motion.h2
              className="text-4xl font-bold mb-4 text-black"
              variants={textVariants}
            >
              About Us
            </motion.h2>
            <motion.p
              className="text-lg leading-loose"
              variants={textVariants}
            >
              We are passionate about films and committed to providing
              comprehensive production solutions. Our team of experienced
              professionals deliver exceptional results for every project we
              undertake, from concept to completion.
            </motion.p>
            <motion.p
              className="text-lg leading-loose mt-4"
              variants={textVariants}
            >
              At Avendia, we combine creativity, technical expertise, and a deep
              understanding of the industry to bring stories to life on screen.
              Whether it's a feature film, commercial, or corporate video, we
              pride ourselves on exceeding expectations and crafting visually
              stunning and compelling content.
            </motion.p>
          </motion.div>
        </section>
      </div>
    </motion.div>
  );

  // Render the appropriate layout based on the screen size
  return isMobile ? mobileLayout : desktopLayout;
};

export default AboutUs;
