import React from 'react';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; // Import icons

const Contacts = () => {
  // Variants for fade-in effect
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      className="min-h-screen w-full text-white flex items-center justify-center bg-cover bg-center overflow-hidden relative"
      style={{ backgroundImage: "url('/assets/Contact US Base.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      
      <section className="relative text-center p-10 rounded-lg max-w-lg z-10 bg-opacity-50 bg-black shadow-lg">
        <motion.h2 
          className="text-2xl md:text-4xl  mb-6 text-yellow-400 tracking-wide"
          variants={containerVariants}
        >
          Avendia Pte Ltd
        </motion.h2>

        {/* Address with Map Marker Icon */}
        <motion.p 
          className="text-lg flex items-center justify-center mb-6"
          variants={containerVariants}
        >
          <FaMapMarkerAlt className="mr-3 text-yellow-400 text-2xl" /> {/* Icon */}
          1, Pickering Street, Level 8, Great Eastern Centre, Singapore - 048659
        </motion.p>

        {/* Email with Envelope Icon */}
        <motion.p 
          className="text-lg flex items-center justify-center mb-6"
          variants={containerVariants}
        >
          <FaEnvelope className="mr-3 text-yellow-400 text-2xl" /> {/* Icon */}
          <a 
            href="mailto:Office@avendia.co" 
            className="inline-block px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-black rounded-lg font-semibold transition-colors"
          >
            Office@avendia.co
          </a>
        </motion.p>
      </section>
    </motion.div>
  );
}

export default Contacts;
