import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Services = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState("10rem"); // Default value for smaller screens

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Adjust height dynamically based on screen size and detect mobile
      if (width >= 1884) {
        setDynamicHeight("16rem");
        setIsMobile(false);
      } else if (width >= 1080) {
        setDynamicHeight("10rem");
        setIsMobile(false);
      } else if (width < 768) {
        setDynamicHeight("6rem");
        setIsMobile(true); // Mobile layout for screens less than 768px
      } else {
        setIsMobile(false);
      }
    };

    // Set the initial height and check for mobile
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Animation variants for text and container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.2,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: [0.42, 0, 0.58, 1], // Smooth ease-in-out cubic bezier
      },
    },
  };

  // Mobile Layout: Simple vertical stacking with circle images
  const mobileLayout = (
    <motion.div
      className="bg-cover bg-center min-h-screen w-screen  text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/Casting Base.png')" }} // Replace with your mobile background image
    >
      <motion.div
        className="flex flex-col items-center justify-center text-left p-6 bg-gradient-to-t from-gray-400 via-black/70 to-transparent min-h-screen"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2
          className="text-4xl  mb-4 text-black tracking-wide"
          variants={textVariants}
        >
          Our Services
        </motion.h2>
  
        {/* Circular Image and Content Cards */}
        <div className="grid grid-cols-1 gap-8 w-full max-w-lg">
          {/* Casting Section */}
          <motion.div
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black p-6 rounded-3xl shadow-xl transform hover:scale-105 transition-transform duration-300 relative"
            variants={textVariants}
          >
            <img
              src="/assets/Casting Image.png"
              alt="Casting"
              className="w-28 h-28 mx-auto mb-4 rounded-full shadow-md border-4 border-white"
            />
            <h3 className="text-2xl font-semibold mb-3 text-white">
              Casting
            </h3>
            <p className="text-base leading-relaxed text-white">
              At Avendia, we specialize in finding the perfect actors to bring
              out your characters. Our extensive network and casting expertise
              ensure that we deliver exceptional results for every project.
            </p>
            <p className="text-sm leading-relaxed mt-4 text-gray-200">
              <strong>Our Casting Process:</strong>
              <br />
              <strong>Talent Scouting:</strong> We actively seek out promising
              talent through various channels.
              <br />
              <strong>Auditions:</strong> We conduct auditions to assess actors'
              suitability for specific roles.
              <br />
              <strong>Selection:</strong> We carefully select the most
              appropriate actors based on their skills and the project’s
              requirements.
            </p>
          </motion.div>
  
          {/* Crew Section */}
          <motion.div
            className="bg-gradient-to-r from-pink-400 to-pink-600 text-black p-6 rounded-3xl shadow-xl transform hover:scale-105 transition-transform duration-300 relative"
            variants={textVariants}
          >
            <img
              src="/assets/Crew Image.png"
              alt="Crew"
              className="w-28 h-28 rounded-full mx-auto mb-4 shadow-md border-4 border-white"
            />
            <h3 className="text-2xl font-semibold mb-3 text-white">
              Crew
            </h3>
            <p className="text-base leading-relaxed text-white">
              Access a team of skilled professionals to bring your vision to
              life. Our crew consists of highly skilled and experienced
              professionals who are passionate about their craft.
            </p>
            <p className="text-sm leading-relaxed mt-4 text-gray-200">
              <strong>Our Crew Members:</strong>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Directors</li>
                <li>Producers</li>
                <li>Cinematographers</li>
                <li>Editors</li>
                <li>Sound Engineers</li>
                <li>Production Designers</li>
              </ul>
            </p>
          </motion.div>
  
          {/* Equipments Section */}
          <motion.div
            className="bg-gradient-to-r from-purple-400 to-purple-600 text-black p-6 rounded-3xl shadow-xl transform hover:scale-105 transition-transform duration-300 relative"
            variants={textVariants}
          >
            <img
              src="/assets/Equipments Image.png"
              alt="Equipments"
              className="w-28 h-28 rounded-full mx-auto mb-4 shadow-md border-4 border-white"
            />
            <h3 className="text-2xl font-semibold mb-3 text-white">
              Equipments
            </h3>
            <p className="text-base leading-relaxed text-white">
              Rent top-of-the-line equipment for seamless production. We provide
              a wide range of high-quality equipment for rental, ensuring that
              your production has everything it needs to succeed.
            </p>
            <p className="text-sm leading-relaxed mt-4 text-gray-200">
              <strong>Our Equipment:</strong>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Cameras</li>
                <li>Lenses</li>
                <li>Lighting</li>
                <li>Sound Equipment</li>
                <li>Grip Equipment</li>
                <li>Production Equipment</li>
              </ul>
            </p>
          </motion.div>
  
          {/* Post-Production Section */}
          <motion.div
            className="bg-gradient-to-r from-red-400 to-red-600 text-black p-6 rounded-3xl shadow-xl transform hover:scale-105 transition-transform duration-300 relative"
            variants={textVariants}
          >
            <img
              src="/assets/Post Production Image.png"
              alt="Post Production"
              className="w-28 h-28 rounded-full mx-auto mb-4 shadow-md border-4 border-white"
            />
            <h3 className="text-2xl font-semibold mb-3 text-white">
              Post-Production
            </h3>
            <p className="text-base leading-relaxed text-white">
              Avendia offers a comprehensive range of post-production services
              to ensure your project is completed to the highest standard. From
              editing and color grading to sound design and visual effects, we
              bring your vision to life with precision and creativity.
            </p>
            <p className="text-sm leading-relaxed mt-4 text-gray-200">
              <strong>Our Post Production Services:</strong>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Editing</li>
                <li>Color Grading</li>
                <li>Sound Design</li>
                <li>Visual Effects</li>
              </ul>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
  

  // Desktop Layout: Complex layout with images and text
  const desktopLayout = (
    <motion.div
      className="snap-y snap-mandatory h-screen overflow-y-scroll overflow-x-hidden"
      style={{ scrollSnapType: "y mandatory" }}
    >
      {/* Casting Section */}
      <motion.section
        className="relative snap-start h-screen flex items-center"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true, amount: 0.5 }}
      >
        <img
          src="/assets/Casting Base.png"
          alt="Casting Background"
          className="w-full h-full object-cover"
        />
        <div
          className="absolute bottom-0 w-screen"
          style={{ height: `calc(100% - ${dynamicHeight}) ` }}
        >
          {/* Image Container */}
          <motion.div className="md:w-1/2 h-full flex justify-center items-center">
            <motion.img
              src="/assets/Casting Image.png"
              alt="Casting"
              className="absolute left-0 top-0 h-full w-[55%] md:w-[45%] lg:w-[40%] xl:w-[35%]"
              initial={{ opacity: 0, x: -500 }}
              animate={{ opacity: 1, x: 0, duration: 4.5 }}
              whileHover={{
                scale: 1.05,
                rotate: 5,
                transition: { duration: 1.5, ease: "easeInOut" },
              }}
            />
          </motion.div>

          {/* Text Container */}
          <motion.div className="z-10 flex flex-col justify-center items-end text-left px-4 md:px-12 lg:px-36 h-full">
            <motion.div
              className="absolute w-1/2 max-w-[40rem] -top-12 leading-loose flex flex-col text-white"
              initial={{ opacity: 0, x: 500 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
            >
              <h2 className="text-4xl md:text-5xl text-black font-bold mb-4">
                Casting
              </h2>
              <p className="mb-4">
                At Avendia, we specialize in finding the perfect actors to bring
                out your characters. Our extensive network and casting expertise
                ensure that we deliver exceptional results for every project.
              </p>
              <p className="mb-4">
                <strong>Our Casting Process:</strong>
                <br />
                <strong>Talent Scouting:</strong> We actively seek out promising
                talent through various channels.
                <br />
                <strong>Auditions:</strong> We conduct auditions to assess
                actors' suitability for specific roles.
                <br />
                <strong>Selection:</strong> We carefully select the most
                appropriate actors based on their skills and the project’s
                requirements.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {/* Crew Section */}
      <motion.section
        className="relative snap-start h-screen flex items-center"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: false, amount: 0.5 }}
      >
        <img
          src="/assets/Crew Base.png"
          alt="Crew Background"
          className="w-full h-full object-cover"
        />
        <div
          className="absolute bottom-0 w-screen"
          style={{ height: `calc(100% - ${dynamicHeight}) ` }}
        >
          {/* Image Container */}
          <motion.div
            className="md:w-1/2 h-full flex justify-center items-center"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.img
              src="/assets/Crew Image.png"
              alt="Crew"
              className="absolute right-0 top-0 h-full w-[55%] md:w-[45%] lg:w-[40%] xl:w-[35%]"
              whileHover={{
                scale: 1.05,
                rotate: -5,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
            />
          </motion.div>

          {/* Text Container */}
          <motion.div
            className="z-10 flex flex-col justify-center items-start text-left px-4 md:px-12 lg:px-36 h-full"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.div
              className="absolute w-1/2 max-w-[40rem] -top-12 flex flex-col leading-loose text-white"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
            >
              <h2 className="text-4xl md:text-5xl text-black font-bold mb-4">
                Crew
              </h2>
              <p className="mb-4">
                Access a team of skilled professionals to bring your vision to
                life. Our crew consists of highly skilled and experienced
                professionals who are passionate about their craft.
              </p>
              <p className="mb-4">
                <strong>Our Crew Members:</strong>
                <ul className="list-disc pl-4 mt-2">
                  <li>Directors</li>
                  <li>Producers</li>
                  <li>Cinematographers</li>
                  <li>Editors</li>
                  <li>Sound Engineers</li>
                  <li>Production Designers</li>
                </ul>
              </p>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {/* Equipments Section */}
      <motion.section
        className="relative snap-start h-screen flex items-center"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true, amount: 0.5 }}
      >
        <img
          src="/assets/Equipments Base.png"
          alt="Equipments Background"
          className="w-full h-full object-cover"
        />
        <div
          className="absolute bottom-0 w-screen"
          style={{ height: `calc(100% - ${dynamicHeight}) ` }}
        >
          {/* Image Container */}
          <motion.div
            className="md:w-1/2 h-full flex justify-center items-center"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.img
              src="/assets/Equipments Image.png"
              alt="Equipments"
              className="absolute left-0 top-0 h-full w-[55%] md:w-[45%] lg:w-[40%] xl:w-[35%]"
              whileHover={{
                scale: 1.05,
                rotate: 5,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
            />
          </motion.div>

          {/* Text Container */}
          <motion.div
            className="z-10 flex flex-col justify-center items-end text-left px-4 md:px-12 leading-loose lg:px-36 h-full"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.div
              className="absolute w-1/2 max-w-xl -top-12 flex flex-col text-white space-y-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
            >
              <h2 className="text-4xl md:text-5xl text-black font-bold">
                Equipments
              </h2>
              <p className="text-lg">
                Rent top-of-the-line equipment for seamless production. We
                provide a wide range of high-quality equipment for rental,
                ensuring that your production has everything it needs to
                succeed.
              </p>
              <div>
                <strong className="text-lg">Our Equipment:</strong>
                <ul className="list-disc pl-5 mt-2 space-y-1">
                  <li>Cameras</li>
                  <li>Lenses</li>
                  <li>Lighting</li>
                  <li className="font-bold">Sound Equipment</li>
                  <li className="font-bold">Grip Equipment</li>
                  <li className="font-bold">Production Equipment</li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {/* Post-Production Section */}
      <motion.section
        className="relative snap-start h-screen flex items-center"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true, amount: 0.5 }}
      >
        <img
          src="/assets/Post Production Base.png"
          alt="Post-Production Background"
          className="w-full h-full object-cover"
        />
        <div
          className="absolute bottom-0 w-screen"
          style={{ height: `calc(100% - ${dynamicHeight}) ` }}
        >
          {/* Image Container */}
          <motion.div
            className="md:w-1/2 h-full flex justify-center items-center"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.img
              src="/assets/Post Production Image.png"
              alt="Post-Production"
              className="absolute right-0 top-0 h-full w-[55%] md:w-[45%] lg:w-[40%] xl:w-[35%]"
              whileHover={{
                scale: 1.05,
                rotate: -5,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
            />
          </motion.div>

          {/* Text Container */}
          <motion.div
            className="z-10 flex flex-col justify-center items-start text-left px-4 md:px-12 lg:px-36 h-full"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
          >
            <motion.div
              className="absolute w-1/2 max-w-xl -top-12 flex flex-col text-white leading-loose  space-y-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5, ease: [0.42, 0, 0.58, 1] }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
            >
              <h2 className="text-4xl md:text-5xl text-black font-bold">
                Post-Production
              </h2>
              <p className="text-lg">
                Avendia offers a comprehensive range of post-production services
                to ensure your project is completed to the highest standard.
                From editing and color grading to sound design and visual
                effects, we bring your vision to life with precision and
                creativity.
              </p>
              <div>
                <strong className="text-lg">
                  Our Post Production Services:
                </strong>
                <ul className="list-disc pl-5 mt-2 space-y-1">
                  <li>Editing</li>
                  <li>Color Grading</li>
                  <li>Sound Design</li>
                  <li>Visual Effects</li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </motion.div>
  );

  // Render mobile or desktop layout based on screen size
  return isMobile ? mobileLayout : desktopLayout;
};

export default Services;
