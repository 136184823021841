import React from 'react';
import { easeIn, easeInOut, motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

const Home = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Dynamically adjust margins and spacing based on screen size
      const calculatedMarginTop = width >= 1280 ? '6rem' : width >= 1024 ? '4rem' : '2rem';
      controls.start({ marginTop: calculatedMarginTop });
    };

    // Set initial margin
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [controls]);

  // Variants for the overall container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
        ease: "easeInOut",
        duration: 1,
      },
    },
  };

  // Variants for each word to animate in from the right with increased spacing and spring effect
  const wordVariants = {
    hidden: { opacity: 0, letterSpacing: "4em" }, // Start with increased spacing and off-screen
    visible: { 
      opacity: 1, 
      letterSpacing: "0em", 
      transition: { 
        type: "spring",
        stiffness: 300,
        damping: 12,
        duration: 2, 
        ease: "easeInOut",
      } 
    },
    hover: {
      scale: 1.1,
      color: "#686868",
      transition: { 
        duration: 0.3,
        ease: "easeInOut",
        type: "spring",
        stiffness: 300,
      },
    },
  };

  // Background image animation
  const imageVariants = {
    hidden: { scale: 1.1 },
    visible: {
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="relative bg-cover bg-center min-h-screen text-white overflow-hidden"
      style={{ backgroundImage: "url('/assets/Landing Page.png')" }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Background Image Animation */}
      <motion.div
        className="absolute inset-0 z-0"
        variants={imageVariants}
        initial="hidden"
        animate="visible"
      />

      {/* Content Section */}
      <motion.section 
        className="overflow-hidden relative z-10 flex flex-col justify-center items-end h-screen text-right px-6 md:px-16 lg:px-24 space-y-12"

      >
        <motion.h1 
          className="text-4xl md:text-6xl lg:text-8xl font-normal leading-tight tracking-tight"
          variants={containerVariants}
        >
          <motion.span 
            variants={wordVariants} 
            className="block"
            initial={{letterSpacing: "4em" }}
            animate={{letterSpacing: "0.19em", transition: { duration: 1, ease: easeIn, type: "spring", stiffness:300,  }}}
            whileHover="hover"
          >
            Crafting
          </motion.span>
          <motion.span 
            variants={wordVariants} 
            className="block text-yellow-500 hover:text-white hover:scale-105 transition-all ease-out"
            initial={{letterSpacing: "4em" }}
            animate={{letterSpacing: "0.19em", transition: { duration: 1, ease: easeIn, type: "spring", stiffness:300 }}}
            whileHover="hover"
          >
            Stories
          </motion.span>
          <motion.span 
            variants={wordVariants} 
            className="block"
            initial={{letterSpacing: "4em" }}
            animate={{letterSpacing: "0.19em", transition: { duration: 1, ease: easeIn, type: "spring", stiffness:300}}}
            whileHover="hover"
          >
            One set
          </motion.span>
          <motion.span 
            variants={wordVariants} 
            className="block"
            initial={{letterSpacing: "4em" }}
            animate={{letterSpacing: "0.19em", transition: { duration: 1, ease: easeIn, type: "spring", stiffness:300 }}}
            whileHover="hover"
          >
            at a time!
          </motion.span>
        </motion.h1>
      </motion.section>
    </motion.div>
  );
};

export default Home;
