import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Import icons
import { motion } from 'framer-motion';

const Footer = () => {
  // Variants for fade-in effect
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.footer
      className="bg-gray-900 text-white py-10 px-6 md:px-16 z-50"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="container overflow grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* About Section */}
        <div className="text-center md:text-left">
          <h3 className="text-xl font-semibold mb-4 text-yellow-500">About Us</h3>
          <p className="text-sm text-gray-400 leading-relaxed">
            Avendia Pte Ltd is a passionate film production company committed to
            providing top-notch services in the industry. We help bring your stories
            to life with our expert team and technical excellence.
          </p>
        </div>

        {/* Quick Links */}
        <div className="text-center">
          <h3 className="text-xl font-semibold mb-4 text-yellow-500">Quick Links</h3>
          <ul className="space-y-2">
            <li><a href="/" className="text-gray-400 hover:text-yellow-400">Home</a></li>
            <li><a href="/about" className="text-gray-400 hover:text-yellow-400">About Us</a></li>
            <li><a href="/services" className="text-gray-400 hover:text-yellow-400">Services</a></li>
            <li><a href="/contacts" className="text-gray-400 hover:text-yellow-400">Contact Us</a></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="text-center md:text-left">
          <h3 className="text-xl font-semibold mb-4 text-yellow-500">Contact Us</h3>
          <p className="text-sm text-gray-400">
            1, Pickering Street, Level 8, Great Eastern Centre, Singapore - 048659
          </p>
          <p className="text-sm text-gray-400 mt-2">
            Email: <a href="mailto:office@avendia.co" className="underline">office@avendia.co</a>
          </p>
          <div className="flex justify-center md:justify-start space-x-4 mt-4">
            {/* Social Media Icons */}
            <motion.a href="#" className="text-yellow-500" whileHover={{ scale: 1.2 }}>
              <FaFacebookF />
            </motion.a>
            <motion.a href="#" className="text-yellow-500" whileHover={{ scale: 1.2 }}>
              <FaTwitter />
            </motion.a>
            <motion.a href="#" className="text-yellow-500" whileHover={{ scale: 1.2 }}>
              <FaInstagram />
            </motion.a>
            <motion.a href="#" className="text-yellow-500" whileHover={{ scale: 1.2 }}>
              <FaLinkedinIn />
            </motion.a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-8 border-t border-gray-700 pt-6 text-center text-gray-500 text-sm">
        © {new Date().getFullYear()} Avendia Pte Ltd. All Rights Reserved.
      </div>
    </motion.footer>
  );
};

export default Footer;
